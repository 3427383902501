export default function getMailTemplate(
	name,
	amount,
	mob_no,
	towards,
	trns_ref
) {
	return `
  
  <!DOCTYPE html>
<html lang="en">
	<head>
		<meta charset="UTF-8" />
	</head>
	<body style="font-family: sans-serif; padding: 0px; margin: 0px">
		<div
			class="header"
			style="
				text-align: center;
				background-color: rgb(255, 136, 0);
				color: white;
				padding: 20px;
			"
		>
			<h1>ARUL THARUM MAHAPERIYAVA CHARITABLE TRUST (ATMC TRUST)</h1>
		</div>
		<div class="thx" style="background-color: antiquewhite; padding: 20px">
			<h1>Namaskaram ${name},</h1>
			<p>Thank you for your donation.</p>
			<p>Please find the receipt attached herewith.</p>
		</div>
		<div
			class="donation-dtls"
			style="background-color: antiquewhite; padding: 20px; text-align: center"
		>
			<div class="txt-center">
				<h2>Rs. ${amount}/-</h2>
				<h4>DONATION DETAILS</h4>
			</div>
			<div class="dtls">
				<p>Mobile No: ${mob_no}</p>
				<p>Towards: ${towards}</p>
				<p>Transaction Reference: ${trns_ref}</p>
			</div>
			<div class="don-btn" style="padding: 20px 0px">
				<a
					href="https://arultharummahaperiyava.com/online-donation"
					style="
						background-color: rgb(255, 136, 0);
						padding: 15px;
						color: white;
						text-decoration: none;
						border-radius: 5px;
					"
					>Make another donation</a
				>
			</div>
			<div class="sys-msg" style="text-align: center">
				<p><em>This is a system generated mail. Please do not reply</em></p>
			</div>
		</div>
		<div class="footer" style="text-align: center">
			<p>© Arul Tharum Mahaperiyava Charitable Trust (ATMC Trust)</p>
			<div class="icons">
				<a href="https://www.facebook.com/groups/528581191697429/"
					>Facebook Page</a
				>
				<a href="https://www.youtube.com/c/arultharummahaperiyavagroup"
					>Youtube</a
				>
				<a href="https://arultharummahaperiyava.com/join">Join us</a>
			</div>
		</div>
	</body>
</html>
  
  `;
}
