import React from "react";

function Terms() {
	return (
		<div style={{ padding: 20 }}>
			<h2>
				<strong>Terms and Conditions</strong>
			</h2>

			<a href="/">Go to Home Page</a>

			<p>Welcome to arultharummahaperiyava.com!</p>

			<p>
				These terms and conditions outline the rules and regulations for the use
				of Arul Tharum Mahaperiyava Charitable Trust (ATMC Trust) Website,
				<a
					href="https://arultharummahaperiyava.com/"
					target="_blank"
					rel="nofollow noreferrer noopener"
				>
					https://arultharummahaperiyava.com
				</a>
				&nbsp;and our mobile application
			</p>

			<p>
				By accessing, browsing or otherwise using this website or mobile
				application you voluntarily and unconditionally accept these terms and
				conditions. So please read this agreement carefully before proceeding.
			</p>

			<p>
				The following terminology applies to these Terms and Conditions, Privacy
				Statement and Disclaimer Notice and all Agreements: "You" and "Your"
				refers to you, the person using this website and/or mobile application
				complying with the trust's terms and conditions. "The trust",
				"Ourselves", "We", "Our", “Ourselves” and "Us", refers to ATMC Trust.
				"Party", "Parties", or "Us", refers to both you and ourselves as
				relevant to the context. Any use of the above terminologies or other
				words in the singular, plural, capitalization and/or he/she or they, are
				taken as interchangeable and therefore as referring to same.
			</p>

			<p>
				When you register for any of the services/activities provided by the
				ATMC Trust through the mobile application and website&nbsp;
				<span>
					<a href="https://arultharummahaperiyava.com">
						https://arultharummahaperiyava.com
					</a>
				</span>
				, you will be subject to the rules, guidelines, policies, terms, and
				conditions applicable to such service/activity, and they shall be deemed
				to be incorporated into this Terms and Conditions and shall be
				considered as part and parcel of this Terms and Conditions
			</p>

			<h3>
				<strong>1. Activities performed by us</strong>
			</h3>

			<p>
				Activities performed by us are religious, charitable and not-for-profit.
				We do not intend to benefit from any of the donations that You may pay
				to us through our website or mobile application.
			</p>

			<p>
				For more details regarding the activities performed by us,&nbsp;
				<a
					target="_blank"
					rel="nofollow noreferrer noopener"
					href="https://arultharummahaperiyava.com/activities"
				>
					Visit our website
				</a>
				.
			</p>

			<h3>
				<strong>2. License and Copyright</strong>
			</h3>

			<p>
				Unless otherwise stated, Arul Tharum Mahaperiyava Charitable Trust (ATMC
				Trust) and/or its licensors own the intellectual property rights for all
				material on arultharummahaperiyava.com. All intellectual property rights
				are reserved. You may access this from arultharummahaperiyava.com for
				your own personal use subject to restrictions set in these terms and
				conditions.
			</p>

			<p>You must not in any manner:</p>
			<ol>
				<li>Republish material from arultharummahaperiyava.com</li>
				<li>
					Sell, rent or sub-license material from arultharummahaperiyava.com
				</li>
				<li>
					Reproduce, duplicate or copy material from arultharummahaperiyava.com
				</li>
				<li>Redistribute content from arultharummahaperiyava.com</li>
			</ol>

			<h3>
				<strong>3. Hyperlinking to our Content</strong>
			</h3>

			<p>You may hyperlink to our home page so long as the link:</p>
			<ul>
				<li>is not in any way deceptive and</li>
				<li>
					does not falsely imply sponsorship, endorsement or approval of the
					ATMC Trust.
				</li>
			</ul>

			<p>
				You shall not use any “deep-link”, “page-scrape”, “robot”, “spider” or
				other automatic device, program, algorithm or methodology, or any
				similar or equivalent manual process, to access, acquire, copy or
				monitor any portion of the Website or any Content, or in any way
				reproduce or circumvent the navigational structure or presentation of
				the Website or any Content, to obtain or attempt to obtain any
				materials, documents or information through any means not purposely made
				available through the Website. We reserve the right to bar any such
				activity
			</p>

			<p>
				You shall not attempt to gain unauthorized access to any portion or
				feature of our website, or any other systems or networks connected to
				the Website or to any server, computer, network, or to any of the
				services offered on or through Website, by hacking, password “mining” or
				by any other illegitimate means.
			</p>

			<h3>
				<strong>4. Reservation of Rights</strong>
			</h3>

			<p>
				We reserve the right to direct you to remove all links or any particular
				link to our Website. You approve to immediately remove all links to our
				Website upon such direction.
			</p>

			<h3>
				<strong>5. Donations and refunds</strong>
			</h3>

			<p>
				All donations made by You in our website are deemed to be made
				voluntarily and are non-refundable under any circumstances whatsoever.
			</p>

			<p>
				All donations shall be compulsorily in Indian Rupees acceptable by the
				Union of India. We do not accept any other form of currency.
			</p>

			<p>
				In case a transaction fails and the amount is deducted from your bank
				account, our systems will not consider and account such transaction and
				You shall receive the refund from your banker as per their policies and
				procedures.
			</p>

			<p>
				ATMC Trust will not be responsible or assume any liability whatosover in
				respect of any loss or damage arising directly or indirectly to you due
				to decline or failure of transaction.
			</p>

			<h3>
				<strong>6. Removal of Members and revocation of access</strong>
			</h3>

			<p>
				If we are of the opinion that any of your activities are offensive,
				harmful or otherwise results in breach of any part or whole of these
				terms or any law or enforcement for the time being in force, we shall
				remove or restrict your access to our website, mobile application
				whether entirely or in part at our sole discretion.
			</p>

			<p>
				You shall indemnify and hold harmless ATMC Trust and their respective
				trustees, officers, and employees, from any claim or demand, or actions
				including reasonable attorneys' fees, made by any third party or penalty
				imposed due to or arising out of your breach of this Terms and
				Conditions, privacy Policy and other Policies, or your violation of any
				law, rules or regulations or the rights of a third party.
			</p>

			<h3>
				<strong>7. Disclaimer</strong>
			</h3>

			<p>
				The information contained in this website is for general information
				purposes only. We make no representations or warranties about the
				information's accuracy or completeness. We shall not be held liable for
				any loss or damage that may result from using our website or relying on
				its information
			</p>
			<p>
				Always verify the url and domain name of the page before making any
				donations to us.{" "}
			</p>
			<p>
				We do not accept any reposibility or liability for any loss or damage
				whatsoever towards any payments made by You in any other unofficial or
				unsolicited websites.
			</p>

			<p>
				We do not have any affiliated individuals, agencies or organisations
				that can accept donations on our behalf.
			</p>

			<p>
				We reserves the right, at its sole discretion, to change, modify, add or
				remove portions of these Terms and Conditions, at any time without prior
				notice. It is your responsibility to check these Terms and Conditions
				periodically for changes. Your continued use of the Site following the
				posting of changes will mean that you accept and agree to the changes.
			</p>
		</div>
	);
}

export default Terms;
